<template>
    <div id="chart">
      <apexchart
        type="pie"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </template>
  <script>
  export default {
    name: "bankDonut",
    data() {
        return {
      series: [44, 55, 41, 17],
      
      chartOptions: {
        legend: {
          show: true,
          fontSize: '14px',
        },
        colors: [
          '#3F98FF',
          '#33FEFF',
          '#102640',
          '#4033FF',
        ],
        dataLabels: {
          enabled: false,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        
        labels: ['1.Hafta', '2.Hafta', '3.Hafta', '4.Hafta'],
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: '500px',
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '1.5rem',
                      },
                      value: {
                        fontSize: '1rem',
                      },
                      total: {
                        fontSize: '1.5rem',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],

      },

    };
    },
  };
  </script>
  <style lang=""></style>
  